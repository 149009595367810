
















































































































































































































import { Component, Vue } from 'vue-property-decorator';
import {dateFormat, dateTimeFormat, store} from '../common';
import I18n from '../I18n';
import {BudgetVariants, Comp, Dict, FileStorage, Org} from '../types';
import { SingleOrgField } from '../organizations';
import axios, {} from 'axios';
import {Ax} from "@/utils";
import auth, { IUser } from "@/services/auth";

const i18n = new I18n('modules.budget.staffing_table.file_storage');

/*** Строка в таблице */
interface IRow {
    /*** Данные из базы */
    data: FileStorage;

    /*** Имя фалйа без изменений */
    originName: string;

    /** Признак "Наименование файла редактируется" */
    changeName: boolean;
}




@Component({
    name: 'file-storage',
    methods: {dateTimeFormat, dateFormat},
    components: {'org-field': SingleOrgField}
})

export default class Page extends Vue {
    created() {
        this.$watch('curYear', () => {
            store.reportYear = this.curYear;
            this.resetBudgetVariants();
        });
        this.$watch('selectedOrg', () => {
            store.org = this.selectedOrg;
        });

        this.$watch('selectedGuCode', () => {
          //  console.log('Сработал $watch на  -- selectedGuCode')
            this.resetAbp();
        });
        this.$watch('requiredRegionCode', () => {
            this.resetRegion();
        });
        this.$watch('selectedRegionCode', () => {
            this.resetBudgetVariants();
        });
        this.$watch('selectedBudgetVariant', () => {
            store.budgetVariant = this.selectedBudgetVariant;
            if (this.selectedBudgetVariant) {
                this.reloadFiles()
            }
        });
        this.$watch('selectedAbpCode', () => {
            if (this.selectedAbpCode === null) {
                this.loadingBudgetPrograms = false;
                this.budgetPrograms = [];
            } else {
                this.reloadBudgetPrograms();
            }
        });
        this.$watch('selectedBudgetProgram', () => {
            if ((this.selectedBudgetProgram as unknown) === undefined) {
                this.selectedBudgetProgram = null;
            } else {
                store.budgetProgram = this.selectedBudgetProgram;
                this.reloadFiles()
            }
        });
    }

   async mounted() {
      await this.resetAbp();
      await this.resetRegion();
      await this.reloadFiles()
    }

    // region Утилиты
    private i18n = i18n;

    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    showMsgBoxTwo() {
        this.$bvModal.msgBoxConfirm(`Вы уверены, что хотите удалить файлы? ${Array.from(this.fileIds.values()).join(', ')}`, {
            title: "Подтверждение удаления",
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
        }).then(value => {
            if (value) {
                this.deleteFiles()
            }
        }).catch(err => {
            console.log(`Отмена удаления ${err}`)
        })
    }

    private getRelativePath(fullPath: string): string {
        const lastDotIndex = fullPath.lastIndexOf(".");
        return fullPath.substring(lastDotIndex + 1);
    }

    private  formatDate(date: Date): string {
        return dateFormat(this.$i18n.locale).format(date);
    }

    private timeFormat(date: Date): string {
        return dateTimeFormat(this.$i18n.locale).format(date);
    }

    private (date: Date): string {
        return dateTimeFormat(this.$i18n.locale).format(date);
    }

    private getFormatCode (code: number): string {
        let codeString = String(code);
        while (codeString.length < 3) codeString = '0' + codeString;
        return codeString
    }

    private getPlanPeriod(year: number): string {
        return `${year} - ${year+2}`;
    }

    private toggleChecked(id: number): void {
        this.fileIds.has(id) ? this.fileIds.delete(id) : this.fileIds.add(id);
        this.fileIds = new Set(this.fileIds);
    }

    private get user(): IUser {
        return auth.user;
    }

    private get date(): Date {
        return store.reportDate;
    }

    // region Org, version
    private selectedOrg: Org | null = store.org;

    private get selectedGu(): Dict.Gu | null {
        if (this.selectedOrg === null) return null;
        if (this.selectedOrg.type !== 'GU') return null;
        return this.selectedOrg.gu;
    }

    private get selectedGuCode(): string | null { return (this.selectedGu?.code ?? null); }


    private selectedBudgetVariant: BudgetVariants | null = store.budgetVariant;

    private budgetVariants: BudgetVariants[] = [];

    private loadingBudgetVariants = false;

    private onBudgetVariantChanged(value: BudgetVariants | null | undefined) {
        this.selectedBudgetVariant = (value || null);
    }

    private resetBudgetVariants(): void {
        if (this.selectedRegionCode === null) {
            this.loadingBudgetVariants = false;
            this.budgetVariants = [];
            this.selectedBudgetVariant = null;
        } else {
            this.reloadBudgetVariants();
        }
    }

    private get budgetVariantOptions(): Array<Comp.DropdownItemDef<BudgetVariants>> {
        return this.budgetVariants.map((variant) => {
            const uuid = variant.variantUuid;
            const title = this.i18n.locale.trim().toLowerCase() === 'kk'
                ? variant.nameKk ?? variant.nameRu
                : this.i18n.locale.trim().toLowerCase() === 'en'
                    ? variant.nameEn ?? variant.nameRu
                    : variant.nameRu;
            return { value: variant, text: `${title} [${uuid}]` };
        }).sort((v1, v2) => v1.text.localeCompare(v2.text));
    }

    private selectedBudgetProgram: Dict.EbkFunc | null = store.budgetProgram;


    private selectedRegion: Dict.BudgetRegions | null = null;

    private loadingRegion = false;

    private get selectedRegionCode(): string | null {
        const selectedRegion = this.selectedRegion;
        if (selectedRegion === null) return null;

        return selectedRegion.code;
    }

    private get requiredRegionCode(): string | null {
        const gu = this.selectedGu;
        if (gu === null) return null;

        let result: string;
        if (gu.budgetType === '02') {
            const idRegion = gu.idRegion;
            const idRegionPart = (
                idRegion.length > 2 ?
                    idRegion.substr(0, 2)
                    :
                    idRegion
            );
            result = idRegionPart + '0101';
        } else {
            result = gu.idRegion;
        }

        return result;
    }

    private async resetRegion() {
        if (this.requiredRegionCode === null) {
            this.loadingRegion = false;
            this.selectedRegion = null;
        } else {
            await this.reloadRegion()
        }
    }

    private get selectedRegionText(): string | null {
        const region = this.selectedRegion;
        if (region === null) {
            return null;
        }

        let title: string;
        if (this.i18n.isKazakh) {
            title = region.nameKk || '';
        } else {
            title = region.nameRu || '';
        }

        return `${region.code} - ${title}`;
    }


    private selectedAbp: Dict.EbkFunc | null = null;

    private loadingAbp = false;

    private get selectedAbpCode(): number | null {
        const abp = this.selectedAbp;
        if (abp === null) {
            return null;
        }
        return abp.abp;
    }

    private get selectedAbpText(): string | null {
        const abp = this.selectedAbp;
        if (abp === null) {
            return null;
        }

        let title: string;
        if (this.i18n.isKazakh) {
            title = abp.nameKk || '';
        } else {
            title = abp.nameRu || '';
        }

        return `${abp.abp} - ${title}`;
    }

    private async resetAbp() {
        if (this.selectedGuCode === null) {
            this.loadingAbp = false;
            this.selectedAbp = null;
        } else {
            await this.reloadAbp();
        }
    }

    private budgetPrograms: Dict.EbkFunc[] = [];

    private loadingBudgetPrograms = false;

    private get budgetProgramOptions(): Array<Comp.DropdownItemDef<Dict.EbkFunc>> {
        return this.budgetPrograms.map(program => {
            const code = String(program.prg).padStart(3, '0');
            const title = this.i18n.isKazakh ? program.nameKk ?? '' : program.nameRu ?? '';
            return { value: program, text: `${code} - ${title}` };
        });
    }

    /** Год планового периода */
    private curYear = store.reportYear;

    /** Дата актуальности поле носит информативный характер */
    private actualDate: Date | null = null

    /** Загружаемый файл - должен иметь расширение */
    private file: File | null = null;

    /** Список id элементов  - используется в загрузке формата zip и при удалении файлов */
    private fileIds: Set<number> = new Set<number>();

    /** Список загурженый файлов (FileStorage) */
    private rows: Array<IRow> = [];



    private loading = false;

    /** Кнопка - сброса параметров фильтра - file и actualDate */
    private reset () {
        this.file = null
        this.actualDate = null
    }
    /** Кнопка - сбрасывает изменения в имени файла */
    private cancelChanges(row: IRow) {
        row.changeName = false
        row.data.fileName = String(row.originName)
    }
    /** Приставивает статус, что имя файла редкатируется */
    private nameToBeChanged(row: IRow) {
        row.changeName = true
    }

    /** Кнопка сброса выбраныех строк в таблице и перезагружает данные (FileStorage) */
    private resetAndReloadFiles () {
        this.fileIds.clear()
        this.reloadFiles()
    }

    private getChecked(id: number): boolean {
        return this.fileIds.has(id);
    }

    // Провера ролей пользователей
    private isActiveUpload(): boolean {
        const hasAllRequiredData =
            this.file &&
            this.actualDate &&
            this.selectedBudgetProgram &&
            this.selectedBudgetVariant;

        return !hasAllRequiredData || this.loading;
    }
    private isDownloadEnabled(): boolean {
        return this.fileIds.size === 0;
    }

    private isEdit(): boolean {
        return this.loading;
    }
    private isView(): boolean {
        return !this.user.roles.includes('budget_view')
    }

    private activeDeleteFile(): boolean {
        return this.fileIds.size === 0 || this.loading;
    }




    // Загрузка данных
    private get fields(): Comp.TableFieldDef[] {
        const create = (dataKey: string, i18nKey: string): Comp.TableFieldDef => {
            return {
                key: `data.${dataKey}`,
                label: this.i18n.translate(`table_fields.${i18nKey}`)
            };
        };
        return [
            create('id', 'id'),
            create('curYear', 'plan_period'),
            create('region', 'region_code'),
            create('fileName', 'file_name'),
            create('abp', 'abp'),
            create('org', 'org'),
            create('prg', 'budget_program'),
            create('actualDate', 'actual_date'),
            create('uploadDate', 'upload_date'),
            // Выбранный элемент
            { key: 'checkbox', label: '' },
            // Действия
            { key: 'actions', label: '' },
        ];
    }

    private async reloadAbp(): Promise<void> {
        const selectedGuCode = this.selectedGuCode;
        const previousAbp = this.selectedAbp?.abp ?? -1

        if (selectedGuCode === null) {
            console.error('Cannot reload selected ABP - selected GA code is null');
            return;
        }

        const date = this.date.getTime();

        if (this.loadingAbp) {
            console.error('Cannot reload ABP - another loading is running');
            return;
        }

        this.loadingAbp = true;

        await new Promise<void>((resolve, reject) => {
            Ax<Dict.EbkFunc | null>(
                {url: `/api/budget/staffing_table/report/abp?ga-code=${selectedGuCode}&date=${date}`},
                data => {
                    this.selectedAbp = data;
                    resolve();
                },
                error => {
                    this.toast('danger', 'Loading ABP', `Error while loading ABP - ${error}`);
                    this.selectedAbp = null;
                    reject()
                },
                () => {
                    this.loadingAbp = false;
                    if (this.selectedBudgetProgram?.abp !== this.selectedAbp?.abp) {
                        this.selectedBudgetProgram = null;
                        this.rows = [];
                    }
                    if (store.abp?.abp === previousAbp)
                        this.reloadFiles()
                }
            );
        });
    }

    private reloadBudgetPrograms() {
        const selectedAbpCode = this.selectedAbpCode;
        if (selectedAbpCode === null) {
            console.error('Cannot load budget programs - ABP code is null');
            return;
        }

        if (this.loadingBudgetPrograms) {
            console.error('Cannot load budget programs - another loading is running');
            return;
        }

        this.loadingBudgetPrograms = true;
        Ax<Dict.EbkFunc[]>(
            { url: '/api/budget/staffing_table/report/budget-programs'
                    + `?abp-code=${selectedAbpCode}&date=${this.date.getTime()}` },
            data => {
                if ((this.selectedBudgetProgram !== null) && (this.selectedBudgetProgram.prg !== null)) {
                    const code = this.selectedBudgetProgram.prg;
                    const index = data.findIndex((testProgram) => (testProgram.prg === code));
                    if (index >= 0) {
                        data.splice(index, 1, this.selectedBudgetProgram);
                    } else {
                        this.selectedBudgetProgram = null;
                    }
                }
                this.budgetPrograms = data;
            },
            error => {
                this.toast('danger', 'Loading budget programs', `Error while loading budget programs - ${error}`);
                this.budgetPrograms = [];
            },
            () => { this.loadingBudgetPrograms = false; }
        );
    }


   // Загрука файлов для таблицы
    private async reloadFiles(): Promise<void> {
        const region = this.selectedRegion?.code ?? null

        const org = this.selectedOrg?.code ?? null

        const abp = this.selectedAbp?.abp ?? null

        const prg = this.selectedBudgetProgram?.prg ?? null

        const variantUuid = this.selectedBudgetVariant?.variantUuid ?? null

        const curYear = this.curYear
        if (!region || !org || !abp || !prg || !variantUuid) {
            console.error(`Cannot reload files - some of params are null /region-${region}/org-${org}/abp-${abp}/prg-${prg}/variantUuid-${variantUuid}`);
            return;
        }

        const params = {
            region: region,
            org: org,
            abp: abp,
            prg: prg,
            uuid: variantUuid,
            year: curYear
        };

        this.loading = true
        const rows: Array<IRow> = [];
        try {
            const response = await axios.get('/api/budget/staffing_table/download-files', { params });
            const arrayOfFileStorage: FileStorage[] = response.data;

            arrayOfFileStorage.forEach(fileStorage => {
                const row: IRow = {
                    data: fileStorage,
                    originName: fileStorage.fileName,
                    changeName: false,
                };
                rows.push(row)
            })
            this.rows = [...rows]

        } catch (error) {
            console.error(error);
            this.toast('danger', this.i18n.translate('error.cannot_load_files'), `${error}`)

        } finally {
            this.loading = false
        }
    }


    private async uploadFile(file: File | null) {

        if (this.loading) {
            console.error('Cannot uploadFile file - loading is running');
            return;
        }

        if (file === null) {
            console.error(`No file selected (${file})`);
            return;
        }

        if (file.size > 60 * 1024 * 1024) {
            this.toast('danger', this.i18n.translate('error.cannot_uploadFile'), this.i18n.translate('error.maximum_allowed_size_is_fail'));
            console.error(`The maximum allowed size of 60 MB - size is (${file})`);
            return;
        }


        if (this.actualDate === null) {
            console.error(`actualDate is missing`)
            this.toast('danger', this.i18n.translate('error.empty_date'), `Добавьте дату актуальности`);
            return;
        }

        if (!file.name.toLowerCase().endsWith('.pdf')) {
            console.error(` extension is not - .pdf `)
            this.toast('danger', this.i18n.translate('error.format_file'), `Файл должен быть в формате PDF`);
            return;
        }

        const region = this.selectedRegion?.code ?? null;

        const org = this.selectedOrg?.code ?? null;

        const abp = this.selectedAbp?.abp ?? null;

        const prg = this.selectedBudgetProgram?.prg ?? null;

        const variantUuid = this.selectedBudgetVariant?.variantUuid ?? null;

        const userId = this.user.sub

        const curYear = this.curYear

        if (!region || !org || !abp || !prg || !variantUuid) {
            console.error(`Cannot reload files - some of params are null /region-${region}/org-${org}/abp-${abp}/prg-${prg}/variantUuid-${variantUuid}`);
            return;
        }

        const uploadDate = new Date()
        uploadDate.getTime()
        const actualDateToMilSec = new Date(this.actualDate)
        actualDateToMilSec.getTime()

        const formData = new FormData();
        formData.append('region', region);
        formData.append('org', org);
        formData.append('abp', `${abp}`);
        formData.append('prg', `${prg}`);
        formData.append('fileName', file.name);
        formData.append('variantUuid', variantUuid);
        formData.append('curYear', `${curYear}`);
        formData.append('uploadDate', `${uploadDate}`);
        formData.append('actualDate', `${actualDateToMilSec}`);
        formData.append('keycloakUserId', userId);
        formData.append('fileSize', `${file.size}`);
        formData.append('multipartFile', file);

        this.loading = true
        try {
            await axios.post('/api/budget/staffing_table/storage-upload', formData, {
                headers: {'Accept': 'application/json', 'Content-Type': 'multipart/form-data'}
            });

        } catch (error) {
            this.toast('danger', this.i18n.translate('error.cannot_uploadFile'), this.i18n.translate(`error.${this.getRelativePath(error.response.data.key)}`))
        } finally {
             await this.resetAndReloadFiles()
        }
    }

    private async downloadFile(fileID: number, fileName: string) {
        try {
            const response = await axios.get(`/api/budget/staffing_table/storage-download/${fileID}`, {responseType: 'blob'})
            const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Произошла ошибка при загруке файла', error.toString());
            this.toast('danger', this.i18n.translate('error.cannot_load_files'), `Фаил с ID ${fileID} не найден`)
        }
    }

    private async downloadFileToZip() {
        const fileIds = [...this.fileIds];

        if (fileIds.isEmpty) {
            console.error('Cannot load fileToZip - fileIds ID is empty');
            return;
        }
        try {
            const response = await axios(`/api/budget/staffing_table/storage-download-zip?fileIds=${fileIds}`, {
                method: 'GET',
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/zip'}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Empty`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Произошла ошибка при загруке zip архива', error.toString());
            this.toast('danger', this.i18n.translate('error.cannot_load_zip'), error.toString())
        }
    }

    private async deleteFiles() {
        if (this.fileIds.size === 0) {
            console.error('Cannot delete fileIds has no ids');
            return;
        }
        const fileIdsArray = Array.from(this.fileIds);
        this.loading = true
        try {
            await axios.delete(`/api/budget/staffing_table/delete-storage-files?fileIds=${fileIdsArray}`)
            this.rows = this.rows.filter((row: IRow) => !this.fileIds.has(row.data.id!!))
        } catch (error) {
            console.error(`cannot delete files ids -  ${fileIdsArray}`);
            this.toast('danger', this.i18n.translate('error.cannot_delete_files'), this.i18n.translate(`error.${this.getRelativePath(error.response.data.key)}`))
        } finally {
            this.fileIds.clear()
            this.loading = false
        }
    }

    private async setNewFileName(row: IRow) {
        if (row.data.fileName === row.originName) {
            console.error(`Произошла ошибка при изменении имени файла - ${row.originName}`);
            this.toast('danger', this.i18n.translate('error.cannot_change_name'), "Измените имя файла")
            return;
        }
        const newStaffTabFileStorage: FileStorage = {...row.data};
        try {
            await axios.put(`/api/budget/staffing_table/update-storage-file`, newStaffTabFileStorage)
            this.toast('success', this.i18n.translate('messages.saved'), `Имя файла было изменено на "${row.data.fileName}"`)
            row.originName = String(row.data.fileName)

        } catch (error) {
            console.error(`cannot set file name ${newStaffTabFileStorage.fileName}`, error);
            this.toast('danger', this.i18n.translate('error.cannot_change_name'), error)
            this.cancelChanges(row)
        } finally {
            row.changeName = false
        }
    }

    private reloadBudgetVariants(): void {
        if (this.loadingBudgetVariants) {
            console.error('Cannot load budget variants - another loading is running');
            return;
        }

        const selectedRegionCode = this.selectedRegionCode;
        if (selectedRegionCode === null) {
            console.error('Cannot load budget variants - no selected region (code of selected region is null)');
            return;
        }

        this.loadingBudgetVariants = true;
        Ax<BudgetVariants[]>(
            { url: `/api/budget/staffing_table/budget-variants-storage?year=${this.curYear}&region-code=${selectedRegionCode}` },
            data => {
                if (this.selectedBudgetVariant !== null) {
                    const uuid = this.selectedBudgetVariant.variantUuid;
                    const index = data.findIndex((testVariant) => (testVariant.variantUuid === uuid));
                    if (index >= 0) {
                        data.splice(index, 1, this.selectedBudgetVariant);
                    } else {
                        this.selectedBudgetVariant = null;
                    }
                }
                this.budgetVariants = data;
            },
            error => {
                this.toast('danger', 'Loading budget variants', `Error while loading budget variants - ${error}`);
                this.selectedAbp = null;
            },
            () => { this.loadingBudgetVariants = false; }
        );
    }

    private async reloadRegion(): Promise<void> {
        if (this.loadingRegion) {
            console.error('Cannot load region - another loading is running');
            return;
        }

        const requiredRegionCode = this.requiredRegionCode;
        if (requiredRegionCode === null) {
            console.error('Cannot load region - required region code is null');
            return;
        }

        this.loadingRegion = true;
        this.selectedRegion = null;

        await new Promise<void>((resolve, reject) => {
            Ax<Dict.BudgetRegions | null>(
                {url: `/api/budget/staffing_table/budget-regions/by-code/${requiredRegionCode}`},
                data => {
                    this.selectedRegion = data;
                    resolve();
                },
                error => {
                    this.toast('danger', 'Loading region', `Error while loading region - ${error}`);
                    this.selectedRegion = null;
                    reject(error);
                },
                () => {
                    this.loadingRegion = false;
                }
            );
        });
    }
}

